import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Faq from "../components/Faq/Faq"

const FaqPage = () => (
  <Layout>
    <Seo title="FAQs" />
    <Faq />
  </Layout>
)

export default FaqPage
